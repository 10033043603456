import { render, staticRenderFns } from "./AjukanBerlanggananGudang.vue?vue&type=template&id=427e920a&scoped=true&"
import script from "./AjukanBerlanggananGudang.vue?vue&type=script&lang=js&"
export * from "./AjukanBerlanggananGudang.vue?vue&type=script&lang=js&"
import style0 from "./AjukanBerlanggananGudang.vue?vue&type=style&index=0&id=427e920a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427e920a",
  null
  
)

export default component.exports